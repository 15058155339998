import dayjs from 'dayjs';

export const discountsMixin = {
    data() {
        return {
            loading: false,
            discountTypes: [],
            detail_image_preview: null,
            formData: {
                name: null,
                slug: null,
                discount_amount: null,
                type: null,
                from: null,
                to: null,
                active: false,
                show_on_main_page: false,
                main_banner: null,
                main_banner_delete: false,
                main_banner_mobile: null,
                main_banner_mobile_delete: false,
                preview_image_square: null,
                preview_image_square_delete: false,
                preview_image_hr_rectangle: null,
                preview_image_hr_rectangle_delete: false,
                preview_image_vr_rectangle: null,
                preview_image_vr_rectangle_delete: false,
                detail_image: null,
                detail_image_delete: false,
                description: null,
                content_order: null,
            },
            custom_fields: this.getDefaultCustomFields(),
            seoHeaders: [
                { field: 'code', label: 'Название параметра' },
                { field: 'value', label: 'Значение' },
            ],
        };
    },
    methods: {
        // clearForm() {
        //     for (const key in this.form) {
        //         this.form[key] = null;
        //     }
        // },
        getDefaultCustomFields() {
            return [
                { code: 'title', value: '' },
                { code: 'keywords', value: '' },
                { code: 'description', value: '' },
            ];
        },
        mapFormData() {
            return {
                ...this.formData,
                from: this.formData.from ? new Date(this.formData.from) : null,
                to: this.formData.to ? new Date(this.formData.to) : null,
                custom_fields: this.custom_fields,
            };
        },
        async fetchCreateParams() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/discounts/create`);

                this.discountTypes = data.data.discount_types;

                this.loading = false;
            } catch (ex) {
                this.loading = false;
                this.$router.push('/error-page');
            }
        },
        async fetchEditParams() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/discounts/${this.$route.params.id}/edit`);

                // заполняем данные
                for (const [key, val] of Object.entries(data.data)) {
                    this.formData[key] = val;
                }

                const response = { ...data.data };

                this.formData.from = response.from ? dayjs(response.from).format('YYYY-MM-DDTHH:mm:ss') : null;
                this.formData.to = response.to ? dayjs(response.to).format('YYYY-MM-DDTHH:mm:ss') : null;

                this.custom_fields = response.custom_fields.length
                    ? response.custom_fields
                    : this.getDefaultCustomFields();
            } catch (ex) {
                console.log('cannot fetch discount edit params ' + ex);
                this.$router.push('/error-page');
            } finally {
                this.loading = false;
            }
        },
    },
};
