<template>
    <div>
        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="relative col-span-4">
                    <label for="form-name" class="form-label">
                        Название
                        <sup v-if="v$.formData.name.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.formData.name.$errors.length" for="form-name" class="form-input-text-error">
                        {{ getOverrideMessage(v$.formData.name.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-name"
                        v-model="formData.name"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.formData.name.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="relative col-span-4">
                    <label for="form-slug" class="form-label">
                        Ссылка
                        <sup v-if="v$.formData.slug.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.formData.slug.$errors.length" for="form-slug" class="form-input-text-error">
                        {{ getOverrideMessage(v$.formData.slug.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-slug"
                        v-model="formData.slug"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.formData.slug.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-2 flex items-center pt-6">
                    <input
                        id="form-active"
                        v-model="formData.active"
                        class="show-code form-check-switch flex-none mr-0"
                        type="checkbox"
                    />
                    <label for="form-published" class="form-check-label">Активно</label>
                </div>

                <div class="col-span-2 flex items-center pt-6">
                    <input
                        id="form-active"
                        v-model="formData.show_on_main_page"
                        class="show-code form-check-switch flex-none mr-0"
                        type="checkbox"
                    />
                    <label for="form-published" class="form-check-label">Показывать на главной странице</label>
                </div>

                <div class="col-span-12 grid grid-cols-12 gap-6">
                    <div class="col-span-6 grid grid-cols-12 gap-3">
                        <div class="relative col-span-8">
                            <label for="form-decks_number_int" class="form-label">
                                Размер скидки
                                <sup v-if="v$.formData.discount_amount.required" class="text-theme-6">*</sup>
                            </label>
                            <label
                                v-if="v$.formData.discount_amount.$errors.length"
                                for="form-decks_number_int"
                                class="form-input-text-error"
                            >
                                {{ getOverrideMessage(v$.formData.discount_amount.$errors[0].$message) }}
                            </label>
                            <input
                                id="form-discount_amount"
                                v-model.number="formData.discount_amount"
                                type="number"
                                min="0"
                                class="form-control w-full"
                                :class="{ 'form-input-error': v$.formData.discount_amount.$errors.length }"
                                placeholder=""
                                autocomplete="off"
                            />
                        </div>

                        <div class="col-span-4">
                            <label for="form-ship_class" class="form-label">
                                Тип скидки
                                <sup v-if="v$.formData.type.required" class="text-theme-6">*</sup>
                            </label>
                            <label
                                v-if="v$.formData.type.$errors.length"
                                for="form-ship_class"
                                class="form-input-text-error"
                            >
                                {{ getOverrideMessage(v$.formData.type.$errors[0].$message) }}
                            </label>
                            <select
                                id="form-type"
                                v-model="formData.type"
                                :disabled="!discountTypes.length"
                                name="type"
                                class="form-select sm:mr-2"
                                :class="{ 'form-input-error': v$.formData.type.$errors.length }"
                                autocomplete="off"
                            >
                                <option v-for="{ value, label } in discountTypes" :key="value" :value="value">
                                    {{ label }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-span-6 grid grid-cols-12 gap-3">
                        <div class="col-span-6">
                            <label for="form-cruise_date" class="form-label">Дата начала</label>
                            <input
                                id="form-from"
                                v-model="formData.from"
                                type="datetime-local"
                                class="form-control w-full"
                                autocomplete="off"
                            />
                        </div>

                        <div class="col-span-6">
                            <label for="form-cruise_date" class="form-label">Дата конца</label>
                            <input
                                id="form-to"
                                v-model="formData.to"
                                type="datetime-local"
                                class="form-control w-full"
                                autocomplete="off"
                            />
                        </div>
                    </div>
                </div>

                <div class="relative col-span-8">
                    <label class="form-label">
                        Баннер на главной странице
                        <sup v-if="v$.formData.main_banner.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.formData.main_banner.$errors.length" class="form-image-text-error">
                        {{ getOverrideMessage(v$.formData.main_banner.$errors[0].$message) }}
                    </label>
                    <UploadImage
                        v-model="formData.main_banner"
                        recommended-sizes="1170х200px"
                        :has-error="!!v$.formData.main_banner.$errors.length"
                    ></UploadImage>
                </div>

                <div class="col-span-4">
                    <label class="form-label">Баннер на главной странице (мобильная версия)</label>
                    <UploadImage v-model="formData.main_banner_mobile" recommended-sizes="430x200px"></UploadImage>
                </div>

                <div class="relative col-span-6">
                    <label class="form-label">
                        Изображение в квадратной плитке
                        <sup v-if="v$.formData.preview_image_square.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.formData.preview_image_square.$errors.length" class="form-image-text-error">
                        {{ getOverrideMessage(v$.formData.preview_image_square.$errors[0].$message) }}
                    </label>
                    <UploadImage
                        v-model="formData.preview_image_square"
                        recommended-sizes="270х310px"
                        :has-error="!!v$.formData.preview_image_square.$errors.length"
                    ></UploadImage>
                </div>

                <div class="relative col-span-6">
                    <label class="form-label">
                        Изображение в горизонтально-прямоугольной плитке
                        <sup v-if="v$.formData.preview_image_hr_rectangle.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.formData.preview_image_hr_rectangle.$errors.length" class="form-image-text-error">
                        {{ getOverrideMessage(v$.formData.preview_image_hr_rectangle.$errors[0].$message) }}
                    </label>
                    <UploadImage
                        v-model="formData.preview_image_hr_rectangle"
                        recommended-sizes="570х310px"
                        :has-error="!!v$.formData.preview_image_hr_rectangle.$errors.length"
                    ></UploadImage>
                </div>

                <div class="relative col-span-6">
                    <label class="form-label">
                        Изображение в вертикально-прямоугольной плитке
                        <sup v-if="v$.formData.preview_image_vr_rectangle.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.formData.preview_image_vr_rectangle.$errors.length" class="form-image-text-error">
                        {{ getOverrideMessage(v$.formData.preview_image_vr_rectangle.$errors[0].$message) }}
                    </label>
                    <UploadImage
                        v-model="formData.preview_image_vr_rectangle"
                        recommended-sizes="270х650px"
                        :has-error="!!v$.formData.preview_image_vr_rectangle.$errors.length"
                    ></UploadImage>
                </div>

                <div class="relative col-span-12">
                    <label class="form-label">
                        Изображение на детальной странице
                        <sup v-if="v$.formData.detail_image.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.formData.detail_image.$errors.length" class="form-image-text-error">
                        {{ getOverrideMessage(v$.formData.detail_image.$errors[0].$message) }}
                    </label>
                    <UploadImage
                        v-model="formData.detail_image"
                        recommended-sizes="1170х300px"
                        :has-error="!!v$.formData.detail_image.$errors.length"
                    ></UploadImage>
                </div>

                <div class="col-span-12">
                    <label for="form-description" class="form-label">Описание</label>
                    <CustomWyswig id="form-description" v-model="formData.description" class="form-control w-full" />
                </div>

                <div class="col-span-2">
                    <label for="form-order" class="form-label">Вес порядка</label>
                    <input
                        id="form-order"
                        v-model="formData.content_order"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-12">
                    <label class="form-label font-bold">Кастомные поля</label>
                    <TeamsTable
                        v-if="custom_fields"
                        v-model="custom_fields"
                        :meta="null"
                        :headers="seoHeaders"
                        class="mb-3"
                    />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link :to="{ name: 'discounts' }" class="btn btn-secondary w-24 mr-2 mb-2">
                        Отмена
                    </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import UploadImage from '@/components/upload-image/UploadImage';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import { discountsMixin } from '@/mixins/form/discounts-mixin';
import TeamsTable from '@/components/tables/TeamsTable.vue';
import { helper as $h } from '@/utils/helper';

export default {
    name: 'Create',
    components: { TeamsTable, UploadImage, Preloader, CustomWyswig },
    mixins: [errorResponse, formPreparation, discountsMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    created() {
        this.$store.commit('main/setPageHeader', 'Создать акцию');
        this.fetchCreateParams();
    },
    validations() {
        return {
            formData: {
                name: { required },
                slug: { required },
                type: { required },
                detail_image: { required },
                discount_amount: { required },
                main_banner: { required },
                preview_image_square: { required },
                preview_image_vr_rectangle: { required },
                preview_image_hr_rectangle: { required },
            },
        };
    },
    methods: {
        async save(flag) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;

            try {
                this.form = this.mapFormData();

                const formData = this.formPreparation();
                const { data } = await this.axios.post('/discounts', formData);
                if (flag) {
                    await this.$router.push({ name: 'discount-edit', params: { id: data.data.id } });
                    this.$notify(data.message || 'Акция создана');
                } else {
                    await this.$router.push({ name: 'discounts' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }

            this.loading = false;
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style></style>
